@import url(https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
/* TODO Add site wide styles */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-image: linear-gradient(180deg, #52acff 25%, #ffe32c 100%);
  background-repeat: no-repeat;
}

.link-container i {
  color: white;
  font-size: 30px;
  padding: 10px 12px;
  transition: all 0.3s;
  border-radius: 50%;
}

.link-container {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 100px;
}

.link-buttons {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 820px) {
  .link-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
  }
  .contact {
    padding-top: 60px;
    margin-bottom: -40px;
  }
  .link-buttons {
    width: 100%;
  }
}

.link {
  border: 4px solid white;
  border-radius: 100%;
  transition: all 0.3s;
  margin: 70px 50px 10px 50px;
}

.link:hover {
  border: 5px solid white;
}

.link i:hover {
  background-color: white;
  border: 3px solid #52acff;
  border-radius: 50%;
  color: #52acff;
  padding: 10px 12px;
}

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-label {
  font-family: "Azeret Mono", monospace;
  color: white;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Azeret Mono", monospace;
  color: white;
  margin-top: 20px;
  font-size: 18px;
  margin-right: 40px;
}

.headshot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
  margin-top: 30px;
  margin-bottom: 15px;
}

.headshot img {
  width: 100%;
  height: auto;
}

.email-contact {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-size: 16px;
  text-decoration: none;
  position: absolute;
  transition: all 0.3s ease-in-out;
  right: 0px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 70px;
  right: 100px;
}

.nav-link {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.nav-link:nth-of-type(3) {
  right: 260px;
}

.nav-link:nth-of-type(2) {
  right: 400px;
}

.nav-link:nth-of-type(1) {
  right: 560px;
  white-space: nowrap;
}

.nav-link:hover {
  padding-bottom: 5px;
  border-bottom: 3px solid white;
  cursor: pointer;
}

.email-contact:hover {
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}

.logo {
  position: absolute;
  width: 110px;
  height: auto;
  top: 30px;
  left: 60px;
}

@media only screen and (max-width: 820px) {
  .contact {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 775px) {
  .logo {
    width: 90px;
    height: auto;
    top: 40px;
  }
}

@media only screen and (max-width: 414px) {
  .logo {
    width: 50px;
    height: auto;
    top: 40px;
  }
}

@media only screen and (max-width: 1050px) {
  .email-contact {
    display: none;
  }
  .nav-link {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .link-buttons {
    margin: 0;
    margin-top: 65px;
    width: 90%;
  }

  .link {
    margin: 5px;
    border: 2px solid white;
  }

  .link-box {
    margin: 0 10px;
  }

  .link i {
    font-size: 18px;
  }

  .link-label {
    font-size: 14px;
  }
  .contact {
    margin-top: -60px;
    margin-right: 0px;
  }
  .link-container {
    padding-bottom: 40px;
  }
}

.home-page:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 25px;
  margin-left: 25px;
}

.skills-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 60px;
  margin: 40px 35px;
}

.skills-img img {
  height: auto;
  width: 100%;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.skills-img img:hover {
  transition: 3s;
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
}

.sqlalchemy {
  width: 130px;
  height: 140px;
}

.sqlalchemy img {
  height: 15px;
  width: auto;
}

.skills-header {
  text-align: left;
  margin-left: 63px;
  font-family: "Azeret Mono", monospace;
  font-weight: 500;
  font-size: 35px;
  margin-top: 40px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.66);
  padding-bottom: 20px;
  width: 90%;
  color: white;
  margin-bottom: 30px;
}

.skill-name {
  font-family: "Azeret Mono", monospace;
  margin-top: 15px;
  color: white;
}

.flask-name {
  padding-top: 16px;
}

.sqlalchemy-name {
  position: relative;
  top: 23px;
}

@media only screen and (max-width: 775px) {
  .skills-img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .skills-header {
    margin-left: 15px;
  }

  .skills-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.img-container {
  display: flex;
  align-items: center;
  min-height: 60px;
}

.header-container {
  display: flex;
  justify-content: center;
  padding-top: 250px;
}

@media only screen and (max-width: 1150px) {
  .header-container {
    flex-direction: column;
    align-items: center;
    padding-top: 210px;
  }
  .header-image img {
    padding-top: 50px;
    margin-bottom: -60px;
    margin-right: 80px;
  }
}

.header-intro {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-weight: 600;
  font-size: 45px;
  white-space: nowrap;
}

.header-intro-2 {
  margin-left: 120px;
  font-size: 60px;
  margin-bottom: 20px;
  margin-top: 15px;
  white-space: nowrap;
}

.header-intro-4 {
  margin-left: 60px;
  font-size: 55px;
  margin-bottom: 30px;
  margin-top: 15px;
  white-space: nowrap;
}

@media only screen and (max-width: 775px) {
  .header-intro {
    font-size: 35px;
    margin-left: 15px;
  }
  .header-intro-2 {
    font-size: 40px;
  }
  .header-intro-4 {
    font-size: 40px;
  }
}

.header-image img {
  height: 420px;
  width: auto;
  margin-left: 80px;
}

.header-image {
  position: relative;
  top: -80px;
}

.about-me {
  text-align: center;
  font-family: "Azeret Mono", monospace;
  color: white;
  width: 75%;
  line-height: 1.7;
  font-size: 22px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 550px) {
  .header-intro {
    font-size: 20px;
  }

  .header-intro-2,
  .header-intro-4 {
    font-size: 23px;
  }

  .header-container {
    padding-top: 170px;
    margin-bottom: -50px;
  }

  .header-image img {
    height: 300px;
    width: auto;
    margin: 0px;
  }

  .about-me {
    width: 75%;
    line-height: 1.6;
    font-size: 16px;
  }
}

.projects-header {
  text-align: left;
  margin-left: 63px;
  font-family: "Azeret Mono", monospace;
  font-weight: 500;
  font-size: 35px;
  margin-top: 50px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.66);
  padding-bottom: 20px;
  width: 90%;
  color: white;
  margin-bottom: 60px;
}

.projects {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

.projects-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 1350px) {
  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 2px rgb(97, 97, 97);
  border-radius: 30px;
  width: 400px;
  height: 250px;
  background-color: #fa4a18;
  transition: all 1.5s ease-in-out;
  overflow: hidden;
  position: relative;
  margin: 40px;
}

.nets-card {
  background-color: #505050;
}

.hullabaloo-card {
  background-color: #f4ff2c;
}

.goodTunes-card {
  background-color: #505050;
}

.sidewalk-card {
  background-color: #a50e02;
}

.project-card:hover {
  width: 820px;
  height: 480px;
}

.project-page img {
  width: 450px;
  height: auto;
  position: absolute;
  right: 420px;
  top: 115px;
  border: 1px solid white;
}

.project-page img {
  transition: all 1.8s ease-in-out;
}

.project-card:hover > .project-page img {
  right: 335px;
}

.hullabaloo-page img {
  top: 135px;
  border: 1px solid black;
}

.sidewalk-page img {
  width: 380px;
  height: auto;
  top: 130px;
}

.project-card:hover > .sidewalk-page img {
  right: 375px;
}

.project-image img {
  width: 250px;
  height: auto;
}

.nets-image img {
  width: 90px;
  height: auto;
  margin-bottom: 30px;
}

.goodTunes-image img {
  width: 150px;
  height: auto;
  margin-bottom: 30px;
}

.hullabaloo-image img {
  width: 130px;
  height: auto;
}

.sidewalk-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidewalk-image img {
  width: 100px;
  height: auto;
}

.project-card:hover > .hullabaloo-image img {
  display: flex;
  flex-direction: row;
}

.project-title {
  color: black;
  font-family: "Righteous", cursive;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

.sidewalk-title {
  color: white;
  font-family: "Patrick Hand", cursive;
}

.project-image {
  transition: all 1.5s ease-in-out;
}

.project-card:hover > .project-image {
  margin-bottom: 250px;
}

.project-description {
  font-family: "Azeret Mono", monospace;
  color: white;
  line-height: 1.4;
  width: 340px;
  text-align: center;
  padding-bottom: 20px;
  transition: all 1.5s ease-in-out;
}

.project-card:hover > .project-description {
  margin-right: 280px;
}

.project-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: -180px;
  right: 30px;
  font-family: "Azeret Mono", monospace;
  transition: all 1.5s ease-in-out;
}

.project-links i {
  color: white;
  margin-left: 10px;
  font-size: 25px;
  text-decoration: none;
}

.project-links a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
}

.project-links a:hover {
  padding-right: 8px;
  border-right: 2px solid white;
}

.hullabaloo-links a:hover {
  border-right: 2px solid black;
}

.project-card:hover > .project-links {
  top: 35px;
  right: 40px;
}

.project-features-list {
  font-family: "Azeret Mono", monospace;
  font-size: 14px;
  color: white;
  width: 280px;
  position: absolute;
  top: -205px;
  right: -180px;
  transition: all 1.5s ease-in-out;
}

.project-features-title {
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 2px solid white;
  margin-bottom: 25px;
}

.project-card:hover > .project-features-list {
  top: 115px;
  right: 30px;
}

.project-card:hover > .hullabaloo-features {
  top: 135px;
}

.sidewalk-features {
  top: -205px;
}

.project-card:hover > .sidewalk-features {
  top: 130px;
  right: 55px;
}

.hullabaloo-description,
.hullabaloo-features,
.hullabaloo-links a,
.hullabaloo-links i {
  color: black;
}

.hullabaloo-features-title {
  border-bottom: 2px solid black;
}

.project-techs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  position: absolute;
  bottom: 0px;
  right: -300px;
  transition: all 1.5s ease-in-out;
}

.project-card:hover > .project-techs {
  right: 50px;
  bottom: 30px;
}

.project-card:hover > .hullabaloo-techs {
  right: 40px;
  bottom: 20px;
}

.project-techs-top {
  display: flex;
  justify-content: space-between;
}

.hullabaloo-techs .project-techs-top {
  width: 250px;
}

.project-techs-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-tech img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: auto;
  margin: 10px 20px;
}

.project-tech {
  color: white;
  font-family: "Azeret Mono", monospace;
  font-size: 12px;
}

.hullabaloo-tech {
  color: black;
}

.project-tech div {
  text-align: center;
}

.flask-tech div {
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 820px) {
  .project-card {
    max-width: 370px;
  }

  .project-card:hover {
    width: 370px;
    height: 650px;
  }
}

@media only screen and (max-width: 820px) {
  .projects-header {
    margin-left: 15px;
  }

  .project-card {
    margin-left: 0px;
    margin-right: 0px;
  }

  .project-card:hover > .project-image {
    margin-bottom: 430px;
    margin-right: 150px;
  }

  .project-card:hover > .project-page img {
    width: 360px;
    top: 110px;
    right: 4px;
  }

  .project-card:hover > .project-features-list {
    top: 330px;
    right: 100px;
    width: 250px;
    font-size: 12px;
  }
  .project-techs-top,
  .project-techs-bottom {
    flex-direction: column;
    align-items: center;
  }

  .project-tech img {
    width: 20px;
  }

  .project-card:hover > .project-techs {
    right: -80px;
    bottom: 30px;
  }

  .js-tech img {
    padding-left: 5px;
  }

  .project-description {
    line-height: 1.4;
    width: 280px;
    font-size: 14px;
  }

  .project-card:hover > .project-description {
    margin-right: 80px;
  }

  .project-card:hover > .hullabaloo-features {
    top: 350px;
  }

  .hullabaloo-description {
    width: 280px;
  }

  .project-card:hover > .hullabaloo-description {
    margin-top: 20px;
  }

  .project-card:hover > .hullabaloo-page img {
    top: 120px;
    height: 200px;
  }

  .hullabaloo-techs {
    top: 335px;
  }

  .project-card:hover > .sidewalk-image {
    margin-bottom: 400px;
    margin-top: -20px;
  }

  .project-card:hover > .sidewalk-page img {
    width: 340px;
    height: 180px;
    right: 14px;
    top: 125px;
  }

  .project-card:hover > .project-links {
    top: 30px;
    right: 15px;
  }

  .express-tech img {
    padding-left: 8px;
  }
}

@media only screen and (min-width: 1051px) {
  .dropdown {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .dropdown i {
    color: white;
    font-size: 55px;
  }

  .dropdown {
    position: absolute;
    top: 60px;
    right: 100px;
  }

  .dropdown-clicked {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Azeret Mono", monospace;
    font-size: 25px;
    font-weight: 500;
    right: 80px;
  }

  .dropdown-clicked i {
    align-self: flex-end;
  }

  .dropdown-options {
    background-color: rgba(60, 60, 60, 0.95);
    padding: 30px;
    height: 270px;
    width: 340px;
    border-radius: 20px;
    margin-right: 20px;
  }

  .dropdown-email,
  .dropdown-link {
    text-decoration: none;
    color: white;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }

  .dropdown-link:nth-of-type(1) {
    left: 80px;
    top: 100px;
  }

  .dropdown-link:nth-of-type(2) {
    left: 145px;
    top: 170px;
  }

  .dropdown-link:nth-of-type(3) {
    left: 145px;
    top: 240px;
  }

  .dropdown-email {
    left: 45px;
    top: 310px;
  }

  .dropdown-link:hover,
  .dropdown-email:hover {
    padding-bottom: 8px;
    border-bottom: 2px solid white;
  }
}

@media only screen and (max-width: 415px) {
  .dropdown i {
    font-size: 30px;
  }

  .dropdown {
    top: 55px;
    right: 80px;
  }

  .dropdown-clicked {
    font-size: 20px;
    font-weight: 400;
  }

  .dropdown-options {
    background-color: rgba(60, 60, 60, 0.95);
    padding: 15px;
    height: 150px;
    width: 280px;
    border-radius: 20px;
    margin-right: -25px;
  }

  .dropdown-link:nth-of-type(1) {
    left: 110px;
    top: 50px;
    font-size: 18px;
  }

  .dropdown-link:nth-of-type(2) {
    left: 125px;
    top: 105px;
    font-size: 18px;
  }

  .dropdown-email {
    left: 45px;
    top: 165px;
    font-size: 18px;
  }
}

.experience-header {
  text-align: left;
  margin-left: 63px;
  font-family: "Azeret Mono", monospace;
  font-weight: 500;
  font-size: 35px;
  margin-top: 40px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.66);
  padding-bottom: 20px;
  width: 90%;
  color: white;
  margin-bottom: 30px;
}

