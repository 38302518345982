.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 25px;
  margin-left: 25px;
}

.skills-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 60px;
  margin: 40px 35px;
}

.skills-img img {
  height: auto;
  width: 100%;
  transition: transform 1.5s ease-in-out;
  transform-style: preserve-3d;
}

.skills-img img:hover {
  transition: 3s;
  transform: rotateY(360deg);
}

.sqlalchemy {
  width: 130px;
  height: 140px;
}

.sqlalchemy img {
  height: 15px;
  width: auto;
}

.skills-header {
  text-align: left;
  margin-left: 63px;
  font-family: "Azeret Mono", monospace;
  font-weight: 500;
  font-size: 35px;
  margin-top: 40px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.66);
  padding-bottom: 20px;
  width: 90%;
  color: white;
  margin-bottom: 30px;
}

.skill-name {
  font-family: "Azeret Mono", monospace;
  margin-top: 15px;
  color: white;
}

.flask-name {
  padding-top: 16px;
}

.sqlalchemy-name {
  position: relative;
  top: 23px;
}

@media only screen and (max-width: 775px) {
  .skills-img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .skills-header {
    margin-left: 15px;
  }

  .skills-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.img-container {
  display: flex;
  align-items: center;
  min-height: 60px;
}
