@media only screen and (min-width: 1051px) {
  .dropdown {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .dropdown i {
    color: white;
    font-size: 55px;
  }

  .dropdown {
    position: absolute;
    top: 60px;
    right: 100px;
  }

  .dropdown-clicked {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Azeret Mono", monospace;
    font-size: 25px;
    font-weight: 500;
    right: 80px;
  }

  .dropdown-clicked i {
    align-self: flex-end;
  }

  .dropdown-options {
    background-color: rgba(60, 60, 60, 0.95);
    padding: 30px;
    height: 270px;
    width: 340px;
    border-radius: 20px;
    margin-right: 20px;
  }

  .dropdown-email,
  .dropdown-link {
    text-decoration: none;
    color: white;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }

  .dropdown-link:nth-of-type(1) {
    left: 80px;
    top: 100px;
  }

  .dropdown-link:nth-of-type(2) {
    left: 145px;
    top: 170px;
  }

  .dropdown-link:nth-of-type(3) {
    left: 145px;
    top: 240px;
  }

  .dropdown-email {
    left: 45px;
    top: 310px;
  }

  .dropdown-link:hover,
  .dropdown-email:hover {
    padding-bottom: 8px;
    border-bottom: 2px solid white;
  }
}

@media only screen and (max-width: 415px) {
  .dropdown i {
    font-size: 30px;
  }

  .dropdown {
    top: 55px;
    right: 80px;
  }

  .dropdown-clicked {
    font-size: 20px;
    font-weight: 400;
  }

  .dropdown-options {
    background-color: rgba(60, 60, 60, 0.95);
    padding: 15px;
    height: 150px;
    width: 280px;
    border-radius: 20px;
    margin-right: -25px;
  }

  .dropdown-link:nth-of-type(1) {
    left: 110px;
    top: 50px;
    font-size: 18px;
  }

  .dropdown-link:nth-of-type(2) {
    left: 125px;
    top: 105px;
    font-size: 18px;
  }

  .dropdown-email {
    left: 45px;
    top: 165px;
    font-size: 18px;
  }
}
