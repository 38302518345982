.link-container i {
  color: white;
  font-size: 30px;
  padding: 10px 12px;
  transition: all 0.3s;
  border-radius: 50%;
}

.link-container {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 100px;
}

.link-buttons {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 820px) {
  .link-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
  }
  .contact {
    padding-top: 60px;
    margin-bottom: -40px;
  }
  .link-buttons {
    width: 100%;
  }
}

.link {
  border: 4px solid white;
  border-radius: 100%;
  transition: all 0.3s;
  margin: 70px 50px 10px 50px;
}

.link:hover {
  border: 5px solid white;
}

.link i:hover {
  background-color: white;
  border: 3px solid #52acff;
  border-radius: 50%;
  color: #52acff;
  padding: 10px 12px;
}

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-label {
  font-family: "Azeret Mono", monospace;
  color: white;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Azeret Mono", monospace;
  color: white;
  margin-top: 20px;
  font-size: 18px;
  margin-right: 40px;
}

.headshot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
  margin-top: 30px;
  margin-bottom: 15px;
}

.headshot img {
  width: 100%;
  height: auto;
}

.email-contact {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-size: 16px;
  text-decoration: none;
  position: absolute;
  transition: all 0.3s ease-in-out;
  right: 0px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 70px;
  right: 100px;
}

.nav-link {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.nav-link:nth-of-type(3) {
  right: 260px;
}

.nav-link:nth-of-type(2) {
  right: 400px;
}

.nav-link:nth-of-type(1) {
  right: 560px;
  white-space: nowrap;
}

.nav-link:hover {
  padding-bottom: 5px;
  border-bottom: 3px solid white;
  cursor: pointer;
}

.email-contact:hover {
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}

.logo {
  position: absolute;
  width: 110px;
  height: auto;
  top: 30px;
  left: 60px;
}

@media only screen and (max-width: 820px) {
  .contact {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 775px) {
  .logo {
    width: 90px;
    height: auto;
    top: 40px;
  }
}

@media only screen and (max-width: 414px) {
  .logo {
    width: 50px;
    height: auto;
    top: 40px;
  }
}

@media only screen and (max-width: 1050px) {
  .email-contact {
    display: none;
  }
  .nav-link {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .link-buttons {
    margin: 0;
    margin-top: 65px;
    width: 90%;
  }

  .link {
    margin: 5px;
    border: 2px solid white;
  }

  .link-box {
    margin: 0 10px;
  }

  .link i {
    font-size: 18px;
  }

  .link-label {
    font-size: 14px;
  }
  .contact {
    margin-top: -60px;
    margin-right: 0px;
  }
  .link-container {
    padding-bottom: 40px;
  }
}

.home-page:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
