.header-container {
  display: flex;
  justify-content: center;
  padding-top: 250px;
}

@media only screen and (max-width: 1150px) {
  .header-container {
    flex-direction: column;
    align-items: center;
    padding-top: 210px;
  }
  .header-image img {
    padding-top: 50px;
    margin-bottom: -60px;
    margin-right: 80px;
  }
}

.header-intro {
  font-family: "Azeret Mono", monospace;
  color: white;
  font-weight: 600;
  font-size: 45px;
  white-space: nowrap;
}

.header-intro-2 {
  margin-left: 120px;
  font-size: 60px;
  margin-bottom: 20px;
  margin-top: 15px;
  white-space: nowrap;
}

.header-intro-4 {
  margin-left: 60px;
  font-size: 55px;
  margin-bottom: 30px;
  margin-top: 15px;
  white-space: nowrap;
}

@media only screen and (max-width: 775px) {
  .header-intro {
    font-size: 35px;
    margin-left: 15px;
  }
  .header-intro-2 {
    font-size: 40px;
  }
  .header-intro-4 {
    font-size: 40px;
  }
}

.header-image img {
  height: 420px;
  width: auto;
  margin-left: 80px;
}

.header-image {
  position: relative;
  top: -80px;
}

.about-me {
  text-align: center;
  font-family: "Azeret Mono", monospace;
  color: white;
  width: 75%;
  line-height: 1.7;
  font-size: 22px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 550px) {
  .header-intro {
    font-size: 20px;
  }

  .header-intro-2,
  .header-intro-4 {
    font-size: 23px;
  }

  .header-container {
    padding-top: 170px;
    margin-bottom: -50px;
  }

  .header-image img {
    height: 300px;
    width: auto;
    margin: 0px;
  }

  .about-me {
    width: 75%;
    line-height: 1.6;
    font-size: 16px;
  }
}
